<script lang="ts">
</script>

<script lang="ts" setup>
import { randFullName, randUuid, random } from '@ngneat/falso'
import { UseTimeAgo } from '@vueuse/components'
import { wait } from '~/lib/helpers'

const features = [
  { name: 'Image generation', emoji: '🖼️', description: 'Generate images using AI' },
  { name: 'PDF generation', emoji: '📄', description: 'Create PDF documents' },
  { name: 'CSV export', emoji: '📊', description: 'Export data to CSV files' },
  { name: 'Webhook Sending', emoji: '🔗', description: 'Send webhooks to external services' },
  { name: 'Email Sending', emoji: '✉️', description: 'Send emails to users' },
  { name: 'Event reception', emoji: '🎉', description: 'Receive and process events' },
  { name: 'Image upload', emoji: '📤', description: 'Upload images to the server' },
  { name: 'PDF upload', emoji: '📥', description: 'Upload PDF documents' },
  { name: 'CSV upload', emoji: '📥', description: 'Upload CSV files' },
  { name: 'Webhook receiving', emoji: '🔗', description: 'Receive webhooks from external services' },
  { name: 'Alert sending', emoji: '🚨', description: 'Send alerts to users' },
  { name: 'Notification sending', emoji: '🔔', description: 'Send notifications to users' },
  { name: 'SMS sending', emoji: '📱', description: 'Send SMS messages to users' },
  { name: 'Push notification sending', emoji: '📲', description: 'Send push notifications to users' },
  { name: 'Payment processing', emoji: '💳', description: 'Process payments securely' },
  { name: 'Subscription processing', emoji: '🔄', description: 'Manage user subscriptions' },
  { name: 'User invitation', emoji: '✉️', description: 'Invite users to join the platform' },
  { name: 'Project creation', emoji: '📁', description: 'Create new projects' },
  { name: 'AI processing', emoji: '🤖', description: 'Perform AI-based processing' },
  { name: 'Machine learning processing', emoji: '🧠', description: 'Execute machine learning tasks' },
  { name: 'Data processing', emoji: '💾', description: 'Process and analyze data' },
]

function getRandomFeature() {
  return features[Math.floor(Math.random() * features.length)]
}

interface FeedItem {
  id: string
  customerName: string
  featureName: string
  featureDescription: string
  featureEmoji: string
  date: Date
}

function createRandomItem(): FeedItem {
  const feature = getRandomFeature()
  return {
    id: randUuid(),
    customerName: randFullName(),
    featureName: feature.name,
    featureEmoji: feature.emoji,
    featureDescription: feature.description,
    date: new Date()
  }
}

const items = ref<FeedItem[]>([createRandomItem()])

function updateItems() {
  if (items.value.length < 3) {
    items.value = [createRandomItem(), ...items.value]
    return
  }
  items.value = [createRandomItem(), ...items.value.slice(0, -1)]
}

useIntervalFn(updateItems, 1000)
</script>

<template>
  <div v-auto-animate class=" flex w-96 flex-col space-y-2">
    <div v-for="item in items" :key="item.id" class="card card-compact bg-base-100 shadow-xl">
      <div class="card-body">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-x-1 text-xs text-base-content/70 ">
            <span>new feature usage</span>
            <iconify-icon icon="lucide:arrow-down" class="text-success" />
          </div>
          <div class="flex items-center gap-x-2">
            <div class="text-xs text-base-content/70">
              <UseTimeAgo v-slot="{ timeAgo }" :time="item.date">
                {{ timeAgo }}
              </UseTimeAgo>
            </div>
          </div>
        </div>
        <div class="mx-auto flex max-w-lg items-center gap-x-6 rounded-md px-4 py-2">
          <FeaturesFeatureEmoji :icon="item.featureEmoji" size-class="size-8 sm:size-12 md:size-16" text-size-class="text-base sm:text-xl md:text-3xl" />
          <div class="flex-1">
            <div class="flex items-start justify-between">
              <div>
                <div class="text-xs font-bold text-base-content sm:text-sm md:text-base lg:text-lg">
                  {{ item.featureName }}
                </div>
                <div class="line-clamp-1 max-w-52 text-xs  text-base-content/70 ">
                  {{ item.featureDescription }}
                </div>
              </div>
            </div>
            <div class="mt-4  flex items-center gap-x-2">
              <span class="text-xs">used by</span>
              <div class="text-xs text-base-content/70">
                <div class="badge badge-neutral badge-xs flex max-w-40 cursor-pointer items-center gap-x-1 sm:badge-sm md:badge-md">
                  <span class="max-w-full truncate text-xs sm:text-sm md:text-base">{{ item.customerName || 'Anonymous Customer' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
