<script lang="ts" setup>
import { toast } from 'vue-sonner'
import { ErrorMessage, Field, useForm } from 'vee-validate'
import { z } from 'zod'

defineProps<{
  size?: 'sm' | 'default' | 'lg' | 'icon'
}>()

const email = defineModel('email', { type: String })
const show = defineModel('show', { type: Boolean, default: false })
const sent = ref(false)

const schema = computed(() => z.object({
  email: z.string().email(),
  name: z.string().min(1).max(255),
})
)

const waitlistFormSchema = toTypedSchema(schema.value)

const { errors, handleSubmit, resetForm, setFieldValue } = useForm({
  validationSchema: waitlistFormSchema,
  initialValues: { email: email.value },
})

const { $client } = useNuxtApp()

const sendForm = handleSubmit(async (data) => {
  const response = await $client.external.sendWaitlistRequest.mutate({
    ...data,
    requested_at: new Date(),
  })

  if (!response?.success) {
    toast.error('Failed to send request, please try again later')
    return
  }

  email.value = ''
  sent.value = true

  setTimeout(() => {
    sent.value = false
    email.value = ''
    resetForm()
    show.value = false
  }, 5000)
})

watch(email, (value) => {
  setFieldValue('email', value)
})

watch(show, (value) => {
  if (!value) {
    email.value = undefined
    resetForm()
  }
})
</script>

<template>
  <slot name="trigger">
    <AppButton icon="lucide:send" label="Join Waitlist" icon-position="right" @click="show = true" />
  </slot>
  <DashboardDialogWrapper v-model:show="show">
    <h3 class="text-lg font-bold">
      <iconify-icon icon="lucide:sparkles" /> Join the waitlist
    </h3>
    <p class="py-4">
      Get early access to our platform and start building better products
    </p>

    <a href="https://docs.tracecom.io" target="_blank">
      <div class="absolute bottom-0 right-0 flex rounded-lg bg-purple-500 p-2 dark:bg-yellow-500">
        <span class="text-xs text-white">Powered by <span class="font-black underline">Tracecom.io</span></span>
      </div>
    </a>

    <form v-if="!sent" class="flex  flex-col gap-4 py-6" @submit="sendForm">
      <div class="form-control">
        <label for="name" class="font-bold">
          Name
        </label>
        <Field id="name" name="name" class="input input-bordered " placeholder="John Doe" :class="{ 'input-error': errors.name }" />
        <ErrorMessage as="span" name="name" class="py-1 text-sm text-error">
          {{ errors.name }}
        </ErrorMessage>
      </div>
      <div class="form-control">
        <label for="email" class="font-bold">
          Email
        </label>
        <Field id="email" name="email" type="email" class="input input-bordered " placeholder="mail@example.com" :class="{ 'input-error': errors.email }" />
        <ErrorMessage as="span" name="email" class="py-1 text-sm text-error">
          {{ errors.email }}
        </ErrorMessage>
      </div>
      <AppButton type="submit" icon="lucide:sparkles" label="Join now" icon-position="right" />
    </form>

    <div v-if="sent" class="py-6">
      <div role="alert" class="alert relative ">
        <iconify-icon icon="lucide:thumbs-up" />

        <div>
          <h3 class="font-bold">
            Thank you for joining the waitlist
          </h3>
          <div class="text-xs">
            We will notify you as soon as we are ready to onboard you
          </div>
        </div>
      </div>
    </div>
  </DashboardDialogWrapper>
</template>
