<script lang="ts" setup>
const accordionItems = [
  {
    value: 'step-1',
    title: 'Create Your App',
    content: 'Once you are registered and logged in, Set up your application in Featrack and generate your unique API key.',
    details: [
      { title: 'Add unlimited apps', icon: 'lucide:layout-grid' },
      { title: 'Secured with encrypted API keys', icon: 'lucide:lock-keyhole' },
    ]
  },
  {
    value: 'step-2',
    title: 'Start Tracking',
    content: 'Integrate Featrack\'s API to begin sending feature usage data from your app.',
    details: [
      { title: 'Use any language, it\' just HTTP requests', icon: 'lucide:square-code' },
      { title: 'Or use our HTML integration', icon: 'lucide:file-code' },
    ]
  },
  {
    value: 'step-3',
    title: 'View Insights',
    content: 'Access real-time reports and deep dive into your users behaviors through our dashboard',
    details: [
      { title: 'Filter by features/customers', icon: 'lucide:filter' },
      { title: 'Analyze data on specific period', icon: 'lucide:calendar' },
    ]
  }
]

const step = ref('step-1')
</script>

<template>
  <section id="integration" class=" flex min-h-[80vh] items-center justify-center">
    <div v-motion-fade-visible class="container">
      <h2 class="mx-auto max-w-3xl text-center  text-4xl font-extrabold">
        Get Started <span class="font-black underline">in Minutes</span>: Simple Steps to <span class="font-black underline">Track</span>, <span class="font-black underline">Monitor</span>, and <span class="font-black underline">Grow</span>
      </h2>

      <div class="my-32 flex w-full flex-wrap items-center justify-center gap-16 lg:flex-nowrap lg:gap-x-24 xl:gap-x-32">
        <div v-motion-slide-visible-bottom class="flex max-w-md flex-col gap-y-4">
          <div v-for="item in accordionItems" :key="item.value" class="collapse collapse-arrow bg-base-200/40">
            <input v-model="step" type="radio" name="accordion" :value="item.value">
            <div class="collapse-title text-xl font-medium">
              {{ item.title }}
            </div>
            <div class="collapse-content">
              <p>{{ item.content }}</p>
              <div class="mt-4 flex flex-col gap-y-2">
                <div v-for="detail in item.details" :key="detail.title" class="flex items-center gap-x-1">
                  <div class="flex size-5 place-content-center place-items-center">
                    <iconify-icon :icon="detail.icon" class="text-xs text-primary" />
                  </div>
                  <span class="text-xs font-bold">{{ detail.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-motion-fade-visible class="relative hidden w-3/5 rounded-2xl p-4 lg:block">
          <div class="absolute inset-0 size-full rounded-2xl bg-base-200/30" />

          <img v-if="step === 'step-1'" loading="lazy" width="auto" height="auto" alt="How data are created on Featrack.io" src="/landing/gifs/create_app.gif" class="relative z-10 rounded-md" >
          <img v-if="step === 'step-2'" loading="lazy" width="auto" height="auto" alt="How realtime feature tracking works" src="/landing/gifs/integration.gif" class="relative z-10 rounded-md" >
          <img v-if="step === 'step-3'" loading="lazy" width="400px" height="auto" alt="Real time analytics and insights" src="/landing/images/data_analytics.png" class="relative z-10 rounded-md" >
        </div>
      </div>
    </div>
  </section>
</template>
