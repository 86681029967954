<script lang="ts" setup>
const faq: { question: string, answer: string }[] = [
  {
    question: 'What is Featrack?',
    answer: 'Featrack is a SaaS tool that helps businesses track and monitor feature usage by their customers, providing real-time insights and control over consumption, limits, and plan management.'
  },
  {
    question: 'How do I sign up for the beta version?',
    answer: 'To join the beta, simply sign up on our website and you\'ll be granted access to the platform. We\'re excited to have you on board!'
  },
  {
    question: 'How does Featrack track customer feature usage?',
    answer: 'Featrack integrates with your existing system to monitor how your customers interact with specific features, giving you real-time data and historical insights on their consumption.'
  },
  {
    question: 'Can I customize the usage limits and plans?',
    answer: 'Yes! Featrack allows you to set custom usage limits for each feature and create plans that match your existing billing subscriptions or specific customer needs.'
  },
  {
    question: 'What happens when a customer exceeds their usage limits?',
    answer: 'You will be notified when customers approach or exceed their usage limits, allowing you to take action by offering upgrades or adjusting limits, preventing service interruptions or overuse.'
  },
  {
    question: 'What kind of data can I view in the analytics section?',
    answer: 'The analytics section provides detailed insights into feature usage trends, customer behavior, consumption patterns, and more, helping you make informed business decisions.'
  },
  {
    question: 'Is it difficult to set up Featrack?',
    answer: 'Not at all! Featrack is designed to be easy to set up. Most users can integrate and start tracking feature usage within minutes.'
  },
  {
    question: 'How can I provide feedback during the beta phase?',
    answer: 'We value your feedback! Once you\'re in the beta, you\'ll find a feedback form within the app where you can share your thoughts, suggestions, and any issues you encounter.'
  },
  {
    question: 'Will my data be secure?',
    answer: 'Absolutely. We take data security seriously and ensure that your customer data is encrypted and protected with the latest security measures.'
  },
  {
    question: 'How much will Featrack cost after the beta?',
    answer: 'Pricing for Featrack will be announced after the beta phase. Our goal is to offer flexible pricing plans that match the size and needs of your business.'
  }
]

const faqItem = ref<typeof faq[number]['question']>()
</script>

<template>
  <section id="faq" class="flex items-center  justify-center py-24 ">
    <div class="container  flex flex-wrap justify-center gap-10 xl:flex-nowrap">
      <div v-motion-fade-visible class="flex flex-col items-center justify-center">
        <h2 class="mx-auto max-w-3xl text-center  text-4xl font-extrabold">
          For all your <span class="font-black underline">questions</span>
        </h2>
        <p class="mx-auto mt-4 max-w-2xl text-center">
          We are here to help you. If you have any questions, please don't hesitate to ask.
        </p>
      </div>

      <div v-motion-slide-visible-bottom class="flex justify-center lg:w-1/2">
        <div class="flex flex-col items-stretch gap-y-4">
          <div v-for="item in faq" :key="item.question" class="collapse collapse-arrow bg-base-200/40">
            <input v-model="faqItem" type="radio" name="accordion" :value="item.question">
            <div class="collapse-title text-lg font-medium">
              {{ item.question }}
            </div>
            <div class="collapse-content">
              <p>{{ item.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
