<script lang="ts" setup>
withDefaults(defineProps< {
  mode: 'waitlist' | 'newsletter'
}>(), {
  mode: 'waitlist'
})
const email = ref('')
</script>

<template>
  <section v-if="mode === 'waitlist'" id="get-started" class="background-squares dark relative flex items-center justify-center py-24" data-theme="dark">
    <div class="absolute size-full bg-[rgba(243,243,243,0.5)] dark:bg-[#141b24ec] " />
    <div v-motion-fade-visible class="container z-10 flex flex-col">
      <h2 class="mx-auto max-w-3xl text-center text-4xl font-extrabold">
        Join the  <span class="font-black underline">waitlist</span>.
      </h2>
      <p class="mx-auto mt-4 max-w-2xl text-center">
        Sign up to get early access and be the first to know about our latest updates and news.
      </p>

      <div class="mx-auto mt-8 flex flex-wrap items-center justify-center gap-2 lg:flex-nowrap">
        <input v-model="email" placeholder="Your Email address" class="input flex-1">
        <LandingWaitlistForm v-model:email="email" />
      </div>

      <p class="mt-2 text-center text-xs">
        By signing up, you agree to our <NuxtLink to="/terms" class="text-primary">
          Terms of Service
        </NuxtLink> and <NuxtLink to="/privacy" class="text-primary">
          Privacy Policy
        </NuxtLink>.
      </p>
    </div>
  </section>
  <section v-else id="get-started" class="background-squares dark relative flex items-center justify-center py-24" data-theme="dark">
    <div class="absolute size-full bg-[rgba(243,243,243,0.5)] dark:bg-[#141b24ec] " />
    <div class="container z-10 flex flex-col">
      <h2 class="mx-auto max-w-3xl text-center  text-4xl font-extrabold">
        Get in touch, subscribe to our <span class="font-black underline">Newsletter</span>.
      </h2>
      <p class="mx-auto mt-4 max-w-2xl text-center">
        Sign up to get the latest updates and news about Featrack.io.
      </p>

      <div class="mx-auto mt-8 flex flex-wrap items-center justify-center gap-2 lg:flex-nowrap">
        <input v-model="email" placeholder="Your Email address" class="input grow">
        <LandingWaitlistNewsletterForm v-model:email="email" />
      </div>

      <p class="mt-2 text-center text-xs">
        By signing up, you agree to our <NuxtLink to="/terms" class="text-primary">
          Terms of Service
        </NuxtLink> and <NuxtLink to="/privacy" class="text-primary">
          Privacy Policy
        </NuxtLink>.
      </p>
    </div>
  </section>
</template>
