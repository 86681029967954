<script setup lang="ts">
const icons: { name: string, icon: string }[] = [
  { name: 'JavaScript', icon: 'fluent:javascript-16-regular' },
  { name: 'TypeScript', icon: 'akar-icons:typescript-fill' },
  { name: 'PHP', icon: 'akar-icons:php-fill' },
  { name: 'Ruby', icon: 'mdi:language-ruby' },
  { name: 'Python', icon: 'bx:bxl-python' },

  { name: 'Go', icon: 'simple-icons:go' },
]
</script>

<template>
  <div id="home" class="background-squares-light relative bg-base-200/30">
    <div class="absolute z-0 size-full overflow-x-hidden bg-[rgba(243,243,243,0.5)]  " />
    <section class="container relative z-10 flex min-h-[calc(100vh-6rem)]  items-center justify-center gap-4 py-16">
      <div v-motion-fade>
        <div class="flex flex-col justify-center gap-y-6  ">
          <p class="max-w-2xl text-2xl font-bold sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
            Empower <b>Your SaaS</b> with Real-Time <b>Feature Analytics</b>
          </p>
          <h1 class="max-w-lg  text-lg text-neutral-700">
            Understand how your customers <u class="font-bold">use your product</u> and make <u class="font-bold">data-driven decisions</u> to improve their experience and <u class="font-bold">grow your business</u>.
          </h1>
          <div class=" mt-8 flex flex-wrap gap-x-2 lg:flex-nowrap">
            <!-- <Input v-model="email" placeholder="Your Email address" class="flex-1" /> -->
            <LandingStartButton size="lg" />
          <!-- <LandingWaitlistForm v-model:email="email" /> -->
          </div>
        </div>
        <div class="mt-10 rounded-xl py-4 ">
          <p class="text-sm">
            Easy Integration with
          </p>
          <div class="my-4 flex flex-wrap items-center gap-6">
            <template v-for="icon in icons" :key="icon.name">
              <div class="flex size-10 items-center justify-center rounded-lg bg-base-300 ring  ring-primary/10 ring-offset-2 ring-offset-base-100">
                <iconify-icon :title="icon.name" :icon="icon.icon" class="text-2xl " />
                <span class="sr-only">{{ icon.name }}</span>
              </div>
            </template>
          </div>
          <p class="text-sm">
            And many more...
          </p>
        </div>
      </div>
      <ClientOnly>
        <LandingLiveFeed v-motion-slide-right class="hidden lg:block" />
      </ClientOnly>
    </section>
  </div>
</template>

<style scoped>

</style>
