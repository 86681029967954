<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'

type ProgressVariants = 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'info' | 'neutral'

const props = withDefaults(
  defineProps<{ class?: HTMLAttributes['class'], variant?: ProgressVariants }>(),
  {
    variant: 'primary',
  },
)

const value = defineModel<number>()

const bg = computed(() => {
  switch (props.variant) {
    case 'primary':
      return 'progress-primary'
    case 'secondary':
      return 'progress-secondary'
    case 'info':
      return 'progress-info'
    case 'neutral':
      return 'progress-neutral'
    case 'error':
      return 'progress-error'
    case 'success':
      return 'progress-success'
    case 'warning':
      return 'progress-primary'
    default:
      return 'progress-primary'
  }
})
</script>

<template>
  <progress class="progress" :class="bg" :value="value" :max="100" />
</template>
