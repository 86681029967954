<script lang="ts" setup>
const features: { title: string, icon: string, benefits: string[] }[] = [
  {
    title: 'Customers',
    icon: 'lucide:users-round',
    benefits: [
      'Track individual customer usage in real-time.',
      'Easily assign customers to specific features.',
      'Monitor which customers are nearing or exceeding their usage limits.',
      'Improve customer management with clear insights into consumption patterns.'
    ]
  },
  {
    title: 'Features',
    icon: 'lucide:wand-sparkles',
    benefits: [
      'Manage and track each feature individually for precise insights.',
      'Set different consumption rules per feature, adaptable to customer needs.',
      'Group features for more flexible offerings.',
      'See which features are most popular with customers for better product development decisions.'
    ]
  },
  {
    title: 'Consumptions',
    icon: 'lucide:plug-zap',
    benefits: [
      'Track how customers are consuming specific features across all plans.',
      'Real-time monitoring of feature usage to prevent surprises.',
      'View consumption trends over time to optimize your service offerings.',
      'Easily analyze which customers are using the most resources.'
    ]
  },
  {
    title: 'Analytics',
    icon: 'lucide:chart-spline',
    benefits: [
      'Gain detailed insights into feature usage trends and customer behavior.',
      'View real-time and historical data to make informed decisions.',
      'Identify growth opportunities through upsell potential and usage patterns.',
      'Customize reports to focus on the metrics that matter most to your business.'
    ]
  }
]

const currentFeature = ref(0)
</script>

<template>
  <section id="features" class="flex items-center justify-center  pt-24">
    <div v-motion-fade-visible class="container">
      <h2 class="mx-auto max-w-3xl text-center text-4xl font-extrabold">
        Everything you need to monitor your <span class="font-black underline">users activity</span> and take the <span class="font-black underline">best actions</span>
      </h2>
      <p class="mx-auto mt-4 max-w-2xl">
        Featrack offers a comprehensive set of features to help you track, manage, and optimize your product offerings. From plans and customers to features and analytics, we have you covered.
      </p>
      <div class="mx-auto mb-20 mt-16 grid max-w-3xl grid-cols-1 items-start justify-center   gap-2 md:grid-cols-2 lg:grid-cols-4">
        <div
          v-for="(feature, idx) in features" :key="idx" class="flex cursor-pointer flex-col items-center gap-y-6 rounded-xl py-4 transition-all "
          :class="{ 'bg-primary text-white': currentFeature === idx, 'text-primary hover:bg-primary/10': currentFeature !== idx }" @click="currentFeature = idx"
        >
          <div class="flex w-full items-center justify-center">
            <iconify-icon :icon="feature.icon" class="text-4xl" />
          </div>
          <div class="text-center">
            {{ feature.title }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-base-200/40 py-24">
    <div v-motion-slide-visible-bottom class="container mx-auto max-w-3xl">
      <div class="flex flex-col gap-y-16">
        <div class="flex flex-col gap-y-8">
          <h3 class="text-2xl font-bold">
            {{ features[currentFeature].title }}
          </h3>
          <div class="flex flex-col gap-y-4">
            <div v-for="benefit in features[currentFeature].benefits" :key="benefit" class="flex items-center gap-x-2">
              <div class="flex size-6 place-content-center place-items-center rounded-xl border border-primary/20 bg-primary/30">
                <iconify-icon icon="lucide:check" class="text-primary" />
              </div>
              <span>{{ benefit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
