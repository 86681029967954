<script lang="ts" setup>

</script>

<template>
  <footer class="footer bg-base-200 p-10 py-24 text-base-content" data-theme="dark">
    <aside>
      <div class="w-16">
        <NuxtImg alt="Featrack.io logo" loading="lazy" width="64px" height="64px" sizes="64px" src="/logo_pink.svg" />
      </div>
      <p class="max-w-sm text-xs text-neutral-content">
        Featrack.io is a SaaS tool that helps businesses track and monitor feature usage by their customers. <br>
        Created with ❤️ by Jeepay Jipex.
      </p>
    </aside>
    <nav>
      <span class="footer-title">
        Links
      </span>
      <a href="#benefits" class="link-hover link">Why Featrack.io ?</a>
      <a href="#integration" class="link-hover link">Integration</a>
      <a href="#features" class="link-hover link">Features</a>
      <a href="#faq" class="link-hover link">FAQ</a>
    </nav>
    <nav>
      <span class="footer-title">
        Legal
      </span>
      <a href="/terms" class="link-hover link">Terms of use</a>
      <a href="/privacy" class="link-hover link">Privacy policy</a>
    </nav>
  </footer>
  <div class="flex h-16 items-center justify-between bg-base-300" data-theme="dark">
    <div class="container flex max-w-3xl items-center justify-between gap-4">
      <p class="text-xs text-base-content/70">
        © {{ (new Date()).getFullYear() }} Featrack.io. All rights reserved.
      </p>
      <div class="flex gap-4">
        <a href="https://x.com/jipexx_jean" class="text-lg text-base-content/70 transition-all hover:text-primary">
          <iconify-icon icon="lucide:twitter" />
          <span class="sr-only">My profile on X</span>
        </a>
        <a href="https://github.com/JeepayJipex" class="text-lg text-base-content/70 transition-all hover:text-primary">
          <iconify-icon icon="lucide:github" />
          <span class="sr-only">My profile on GitHub</span>
        </a>
        <!--           <a href="#" class="text-lg text-base-content/70 transition-all hover:text-primary">
            <iconify-icon icon="lucide:instagram" />
            <span class="sr-only">My profile on Instagram</span>
          </a> -->
      </div>
    </div>
  </div>
</template>
