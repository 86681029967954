<script lang="ts" setup>
</script>

<template>
  <section id="benefits" class="flex min-h-[90vh] items-center justify-center py-16">
    <div class="container flex flex-col">
      <h2 v-motion-fade-visible class="mx-auto max-w-3xl text-center  text-4xl font-extrabold">
        Tired of developing <span class="font-black underline">cluttered businesses</span> with <span class="font-black underline">low ROI</span> ?
      </h2>

      <div class="my-32 grid w-full grid-cols-1 flex-wrap justify-center gap-16 lg:grid-cols-2">
        <div v-motion-slide-visible-top class="flex flex-col gap-y-4 rounded-2xl bg-base-200/30 px-6 py-8 ">
          <h3 class="text-2xl font-bold">
            <iconify-icon icon="lucide:thumbs-down" class="text-error" />
            Without Featrack integration
          </h3>
          <div class="mt-6 grow">
            <div class="mb-4 flex flex-col gap-y-2">
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-error/20 bg-error/30">
                  <iconify-icon icon="lucide:x" class="text-error" />
                </div>
                <span>  No visibility into feature usage.</span>
              </div>

              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-error/20 bg-error/30">
                  <iconify-icon icon="lucide:x" class="text-error" />
                </div>
                <span>Missed upsell opportunities.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-error/20 bg-error/30">
                  <iconify-icon icon="lucide:x" class="text-error" />
                </div>
                <span>Unexpected overuse and hidden costs.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-error/20 bg-error/30">
                  <iconify-icon icon="lucide:x" class="text-error" />
                </div>
                <span>Manual, time-consuming tracking.</span>
              </div>

              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-error/20 bg-error/30">
                  <iconify-icon icon="lucide:x" class="text-error" />
                </div>
                <span>Struggles with personalized customer support.</span>
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="mt-2 flex gap-x-2">
            <span class="mt-1 text-xs font-bold">Customer satisfaction :</span>

            <div class="mx-auto max-w-sm flex-1">
              <AppProgress variant="error" :model-value="15" />
              <div class="flex items-center gap-x-2">
                <iconify-icon icon="lucide:frown" class="text-error" />
                <span class="text-sm text-error">15%</span>
              </div>
            </div>
          </div>
        </div>

        <div v-motion-slide-visible-bottom class="flex flex-col gap-y-4 rounded-2xl bg-base-200/30 px-6 py-8 ">
          <h3 class="text-2xl font-bold">
            <iconify-icon icon="lucide:thumbs-up" class="text-success" />
            With Featrack integration
          </h3>
          <div class="mt-6 grow">
            <div class="mb-4 flex flex-col gap-y-2">
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-success/20 bg-success/30">
                  <iconify-icon icon="lucide:check" class="text-success" />
                </div>
                <span>Real-time insights into feature usage.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-success/20 bg-success/30">
                  <iconify-icon icon="lucide:check" class="text-success" />
                </div>
                <span>Proactively offer upgrades when a feature is overused.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-success/20 bg-success/30">
                  <iconify-icon icon="lucide:check" class="text-success" />
                </div>
                <span>Track usages to avoid costly surprises.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-success/20 bg-success/30">
                  <iconify-icon icon="lucide:check" class="text-success" />
                </div>
                <span>Tracking set up in minutes, not hours.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="flex size-6 place-content-center place-items-center rounded-sm border border-success/20 bg-success/30">
                  <iconify-icon icon="lucide:check" class="text-success" />
                </div>
                <span>Boost satisfaction and retention with insights.</span>
              </div>
            </div>
          </div>
          <div class="divider" />
          <div class="mt-2 flex gap-x-2">
            <span class="mt-1 text-xs font-bold">Customer satisfaction :</span>

            <div class="mx-auto max-w-sm flex-1">
              <AppProgress variant="success" :model-value="92" />
              <div class="flex items-center justify-end gap-x-2">
                <iconify-icon icon="lucide:smile" class="text-success" />
                <span class="text-sm text-success"> 92%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
